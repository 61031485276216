import styled from '@emotion/styled';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import CartContainer from 'components/nav/TopNav/CartContainer';
import COLORS, { COLORS_SEMANTIC } from '@zola/zola-ui/src/styles/emotion/colorsBaby';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import ZolaBabyLogo from '@zola/zola-ui/src/components/ZolaBabyLogo';

type PrimaryNavContainerProps = {
  hideContentOnScroll: boolean;
};

export const PrimaryNavContainer = styled.nav<PrimaryNavContainerProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${SPACING.S24};
  background: ${({ hideContentOnScroll }) =>
    hideContentOnScroll ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 1)'};
  transition: background 0.3s linear;
  padding: 0 40px;
  height: 56px;
  ${MEDIA_QUERY.DESKTOP} {
    height: 80px;
  }
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    background: ${COLORS.WHITE_100};
    border-bottom: 1px solid ${COLORS.BLACK_030};
  }
`;

export const StyledLink = styled(LinkV2)`
  color: ${COLORS.PD_ULTRAMARINE_100}!important;
  :hover {
    color: ${COLORS.PD_ULTRAMARINE_085}!important;
  }
  :active,
  :focus {
    color: ${COLORS.PD_ULTRAMARINE_085}!important;
  }
`;

export const NavLeft = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  column-gap: ${SPACING.S24};
  flex: 1;
  ${MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;

export const NavCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;
  ${MEDIA_QUERY.MOBILE} {
    flex: 1;
  }
`;

export const NavRight = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  column-gap: ${SPACING.S24};
  flex: 1;
  ${MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;

export const Logo = styled(ZolaBabyLogo)`
  width: 140px;
  ${MEDIA_QUERY.DESKTOP} {
    width: 200px;
  }
`;

export const StyledCartContainer = styled(CartContainer)`
  margin-right: -${SPACING.SM};
  position: relative;
  display: flex;
  padding: ${SPACING.SM};
  border-radius: 50%;
  color: ${COLORS.PD_ULTRAMARINE_100} !important;
  &:hover,
  &.icon-link--selected {
    color: ${COLORS.PD_ULTRAMARINE_100} !important;
    background-color: ${COLORS.PD_ULTRAMARINE_010};
    cursor: pointer;
  }
  .badge {
    position: absolute;
    top: 2px;
    right: 2px;
    min-width: ${SPACING.SM};
    background-color: ${COLORS_SEMANTIC.NEGATIVE_100};
    border-radius: ${SPACING.SM};
    color: ${COLORS.WHITE_100};
    font-size: ${FONT.SIZE.EXTRA_SMALL};
    font-weight: ${FONT.WEIGHT.BOLD};
    line-height: 1;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }
`;
