import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'reducers/index';
import { showModal } from 'actions/ModalActions';
import {
  PrimaryNavContainer,
  NavLeft,
  NavCenter,
  NavRight,
  Logo,
  StyledLink,
  StyledCartContainer,
} from './PrimaryNav.styles';

const PrimaryNav = ({ hideContentOnScroll }: { hideContentOnScroll: boolean }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const showAppDownloadModal = () => {
    dispatch(
      showModal('BABY_APP_DOWNLOAD_MODAL', {}, { size: 'sm', ariaLabel: 'Registry not visible' })
    );
  };

  return (
    <PrimaryNavContainer hideContentOnScroll={hideContentOnScroll}>
      <NavLeft>
        <StyledLink
          role="button"
          sizes="smaller"
          noUnderline
          noTextTransform
          onClick={showAppDownloadModal}
        >
          Download the app
        </StyledLink>
      </NavLeft>
      <NavCenter>
        <a href="/">
          <Logo />
        </a>
      </NavCenter>
      <NavRight>
        <StyledCartContainer isDesktopNavV3 hideIfEmpty />
        <StyledLink href="/search/baby-registry" sizes="smaller" noUnderline noTextTransform>
          Find a registry
        </StyledLink>
        <StyledLink href="/faq" sizes="smaller" noUnderline noTextTransform>
          Help
        </StyledLink>
      </NavRight>
    </PrimaryNavContainer>
  );
};

export default PrimaryNav;
