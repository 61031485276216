import styled from '@emotion/styled';
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

type BannerContainerProps = {
  hideContentOnScroll: boolean;
};

export const BannerContainer = styled.div<BannerContainerProps>`
  display: none;
  ${MEDIA_QUERY.DESKTOP} {
    display: block;
    height: ${({ hideContentOnScroll }) => (hideContentOnScroll ? '0px' : '56px')};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${SPACING.S24};
    background-color: ${COLORS.TEAL_SAGE_100};
    overflow: hidden;
    transition: height 0.3s linear;
  }
`;
