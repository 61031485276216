import React from 'react';

import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import featureFlags from 'util/featureFlags';
import { getZolaHref } from 'util/getZolaHref';

import { BannerContainer } from './Banner.styles';

type BannerProps = {
  hideContentOnScroll: boolean;
};

const Banner = ({ hideContentOnScroll }: BannerProps): JSX.Element => {
  return (
    <BannerContainer hideContentOnScroll={hideContentOnScroll}>
      <P.BodySmall>You’re on Zola’s baby registry site. Looking for Zola Weddings?</P.BodySmall>
      <LinkV2 href={getZolaHref(featureFlags.getEnvironment())} sizes="smaller" noTextTransform>
        Go to Zola Weddings
      </LinkV2>
    </BannerContainer>
  );
};

export default Banner;
