import React from 'react';

import Banner from '../Banner/Banner';
import PrimaryNav from '../PrimaryNav/PrimaryNav';
import { HorizontalNavWrapper } from './HorizontalNav.styles';

type HorizontalNavProps = {
  hideContentOnScroll: boolean;
};

const HorizontalNav = ({ hideContentOnScroll }: HorizontalNavProps): JSX.Element => {
  return (
    <HorizontalNavWrapper>
      <Banner hideContentOnScroll={hideContentOnScroll} />
      <PrimaryNav hideContentOnScroll={!hideContentOnScroll} />
    </HorizontalNavWrapper>
  );
};

export default HorizontalNav;
