import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import { getUserContext } from 'selectors/user';
import { maybeFetchUser } from 'actions/UserActions';
import _isEmpty from 'lodash/isEmpty';
import useNavData from 'components/navV2/useNavData';
import type { RootState, AppDispatch } from '../../reducers';

import useScrollPosition from '../../hooks/useScrollPosition';
import { NavWrapper } from './BabyNav.styles';
import HorizontalNav from './HorizontalNav/HorizontalNav';

import '../navV2/navV2.less';

type BabyNavProps = NavV2MapStateProps & NavV2MapDispatchProps;

const BabyNav = ({ userContext, maybeFetchUser: maybeFetchUserFn }: BabyNavProps): JSX.Element => {
  const userContextExists = userContext && !_isEmpty(userContext);
  const defaultScrollPos = (typeof window !== 'undefined' && window?.scrollY) || 0;
  const [hideContentOnScroll, setHideContentOnScroll] = useState(defaultScrollPos !== 0);

  const { direction } = useScrollPosition();

  // Show minimized nav until user reaches top of page
  const currentHideContentOnScroll =
    (direction === 'down' || direction === 'up') && defaultScrollPos > 0;

  useEffect(() => {
    if (currentHideContentOnScroll !== hideContentOnScroll) {
      setHideContentOnScroll(currentHideContentOnScroll);
    }
  }, [currentHideContentOnScroll, hideContentOnScroll]);

  // useCallback so the referential check in useNavData's useEffect works as expected
  const onInitNavData = useCallback((passedUserContext: UserContext = {}) => {
    // if (!_isEmpty(passedUserContext)) addUserContextToRedux(passedUserContext);
    console.log(passedUserContext);
  }, []);

  useNavData(onInitNavData, userContext, '');

  if (!userContextExists) {
    maybeFetchUserFn();
  }

  if (!userContext) {
    return <Fragment />;
  }

  return (
    <NavWrapper>
      <HorizontalNav hideContentOnScroll={hideContentOnScroll} />
    </NavWrapper>
  );
};

type NavV2MapStateProps = {
  userContext?: UserContext;
};

const mapStateToProps = (state: RootState): NavV2MapStateProps => ({
  userContext: getUserContext(state),
});

type NavV2MapDispatchProps = {
  // addUserContextToRedux: (userContext: UserContext) => void;
  maybeFetchUser: (shouldForceUserFetch?: boolean) => void;
};

const mapDispatchToProps = (dispatch: AppDispatch): NavV2MapDispatchProps => ({
  // addUserContextToRedux: (userContext) => {
  //   dispatch(receiveUser(userContext));
  // },
  maybeFetchUser: (shouldForceUserFetch) => dispatch(maybeFetchUser(shouldForceUserFetch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BabyNav);
